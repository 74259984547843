<template>
  <div class="spedizioni">
    <h1>Spedizioni</h1>
    <p>Ciao</p>
  </div>
</template>

<script>
export default {
  name: "app",
  created:function() {
    localStorage.idmodule = '3'
  }
}
</script>